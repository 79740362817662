var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"elevation-0 pa-4",staticStyle:{"background":"white"}},[_c('v-card-title',[_vm._v("Influencers "),_c('v-spacer'),_c('v-text-field',{staticClass:"pt-0 pb-2",attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.showTable)?_c('v-data-table',{staticClass:"elevation-0 px-6 pb-4 pt-10",attrs:{"height":"600","fixed-header":"","footer-props":{'items-per-page-options':[15, 30, 50, _vm.totalInfluencers]},"headers":_vm.headers,"items":_vm.influencers,"options":_vm.options,"server-items-length":_vm.totalInfluencers,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-2"},[_c('img',{attrs:{"src":item.photo}})])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btnText",attrs:{"to":{ path: '/influencers/influencer/'+ item.id}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.is_booking",fn:function(ref){
var item = ref.item;
return [(item.is_booking)?_c('span',[_vm._v("Booking")]):_c('span',[_vm._v("Mgmt")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('editInfluencers')),expression:"permissions('editInfluencers')"}],staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item.editedItem)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('deleteInfluencers')),expression:"permissions('deleteInfluencers')"}],attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('editItem',{attrs:{"editedItem":_vm.editedItem},on:{"closeDialogItem":_vm.closeDialogItem}})],1),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","color":"#58c6ef","dark":"","fab":"","fixed":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('createItem',{on:{"closeDialogItem":_vm.closeDialogItem}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }